import React from 'react'
import ContactForm from '../components/Contact/Contact'

const Contact = () => {
  return (
    <div>
      <ContactForm/>
    </div>
  )
}

export default Contact