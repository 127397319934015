import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    email: "",
    phone: "",
    country: "",
    city: "",
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, credentials);
      setSuccess(true);
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (err) {
      setError(err.response?.data || { message: "Registration failed" });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-[80vh] bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-gray-700 mb-6">Register</h2>
        <form className="space-y-4">
          <input
            type="text"
            placeholder="Username"
            id="username"
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:border-orange-500"
            required
          />
          <input
            type="password"
            placeholder="Password"
            id="password"
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:border-orange-500"
            required
          />
          <input
            type="email"
            placeholder="Email"
            id="email"
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:border-orange-500"
            required
          />
          <input
            type="text"
            placeholder="Phone Number"
            id="phone"
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:border-orange-500"
            required
          />
          <input
            type="text"
            placeholder="Country"
            id="country"
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:border-orange-500"
            required
          />
          <input
            type="text"
            placeholder="City"
            id="city"
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:border-orange-500"
            required
          />
          <button
            onClick={handleClick}
            className={`w-full px-4 py-2 font-semibold text-white rounded-lg focus:outline-none ${
              success ? "bg-green-500 cursor-not-allowed" : "bg-orange-400 hover:bg-orange-600"
            }`}
            disabled={success}
          >
            {success ? "Registered!" : "Register"}
          </button>
          {error && <span className="block mt-2 text-center text-red-500 text-sm">{error.message}</span>}
          {success && (
            <span className="block mt-2 text-center text-green-500 text-sm">
              Registration successful! Redirecting to login...
            </span>
          )}
        </form>
      </div>
    </div>
  );
};

export default Register;
