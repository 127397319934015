import React from "react";
import Navbar from "../components/Navbars/Navbar";
import { Outlet, Link } from "react-router-dom";
import Footer from "../components/Footers/Footer";
import OrderPopup from "../components/OrderPopup/OrderPopup";

const Layout = () => {
  const [orderPopup, setOrderPopup] = React.useState(false);

  const handleOrderPopup = () => {
    setOrderPopup(!orderPopup);
  };
  return (
    <>
      <div className="pt-16 md:pt-28">
        <Navbar handleOrderPopup={handleOrderPopup} />
        <Outlet />        
        <OrderPopup orderPopup={orderPopup} setOrderPopup={setOrderPopup} />
        <Footer />
      </div>
            
    </>
  );
};

export default Layout;
