import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import Logo from "../../assets/logo.png";
import { NavLink, Link, useNavigate } from "react-router-dom";
import {
  FaCaretDown,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaGlobeAmericas,
  FaCar,
  FaHome,
  FaMobileAlt,
  FaEnvelope,
  FaWhatsapp,
  FaSignInAlt, 
  FaUserPlus, 
  FaSignOutAlt
} from "react-icons/fa";
import { MdFlight, MdHotel, MdDirectionsBoat, MdTripOrigin, MdNightShelter} from "react-icons/md";
import ResponsiveMenu from "./ResponsiveMenu";
import { HiMenuAlt3, HiMenuAlt1 } from "react-icons/hi";

export const NavbarLinks = [
  {
    name: "Home",
    link: "/",
    icon: FaHome,
  },
  {
    name: "Hotels",
    link: "https://hotels.trendytraveltrips.com/",
    icon: MdHotel,
  },
  {
    name: "Flights",
    link: "https://hotels.trendytraveltrips.com/",
    icon: MdFlight,
  },
  {
    name: "Cruise",
    link: "/Cruise",
    icon: MdDirectionsBoat,
  },
  {
    name: "Cars",
    link: "/Cars",
    icon: FaCar,
  },
  {
    name: "Plan your trip",
    link: "/TripPlan",
    icon: MdTripOrigin
  },
  {
    name: "Insurance",
    link: "/Insurance",
    icon: MdNightShelter
  },
  {
    name: "Contact",
    link: "/Contact",
    icon: FaEnvelope
  }
];

const QuickLinks = [
  // {
  //   name: "Our Services",
  //   link: "/Services",
  // },
  {
    name: "Activities",
    link: "/Packages",
  },
  {
    name: "Plan your trip",
    link: "/TripPlan",
  },
  {
    name: "Insurance",
    link: "/Insurance",
  },
  {
    name: "Contact",
    link: "/Contact",
  }
];

const TravelLinks = [
  {
    name: "Destinations",
    link: "/Destinations",
  },
  {
    name: "Specials",
    link: "/Specials",
  }
];

const Navbar = ({ handleOrderPopup }) => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  
  const { user, dispatch } = useContext(AuthContext); // Access AuthContext

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    localStorage.removeItem("access_token"); // Clear token from localStorage
    navigate("/"); // Redirect to home
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      <nav className="fixed top-0 right-0 w-full z-50 bg-white backdrop-blur-sm text-black shadow-md">
        <div className="bg-gradient-to-r from-primary to-secondary text-white text-md">
          <div className="container py-[2px] sm:block hidden">
            <div className="flex items-center justify-between h-7 my-1">
              <div className="ml-2 inline-flex gap-2">
                <FaFacebook className="text-3xl text-[#EA9937] hover:text-black" />
                <FaInstagram className="text-3xl text-[#EA9937] hover:text-black" />
                <FaLinkedin className="text-3xl text-[#EA9937] hover:text-black" />
                <FaWhatsapp className="text-3xl text-[#EA9937] hover:text-black" />
              </div>    
              <div className="mr-2 inline-flex gap-2">
                <p className="text-lg inline-flex gap-1"><FaEnvelope className="transition-all duration-200 group-hover:rotate-180 mt-1 text-[#EA9937]" /> admin@trendydialog.com</p>
                <p className="text-lg inline-flex gap-1"><FaMobileAlt className="transition-all duration-200 group-hover:rotate-180 mt-1 text-[#EA9937]" /> 073 559 2210</p>
              </div>       
            </div>
          </div>
        </div>
        <div className="w-full lg:container py-3 sm:py-0">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-4 font-bold text-2xl">
              <Link to={"/"} onClick={() => window.scrollTo(0, 0)}>
                <img src={Logo} alt="" className="sm:my-2 w-32 h-10 ml-1" />
              </Link>
            </div>
            <div className="hidden md:block">
              <ul className="flex items-center gap-6 ">
                <li className="py-4">
                  <NavLink to="/" activeClassName="active">
                    <span className="inline-flex gap-1 hover:border-b-2 hover:border-b-orange-600"><FaHome className='h-6 w-6 text-[#aa7734]'/>Home</span>
                  </NavLink>
                </li>
                <li className="py-4">                  
                  <NavLink to="https://hotels.trendytraveltrips.com/" activeClassName="active">                    
                    <span className="inline-flex gap-1 hover:border-b-2 hover:border-b-orange-600"><MdFlight className='h-6 w-6 text-[#aa7734]'/>Flights</span>
                  </NavLink>
                </li>
                <li className="py-4">
                  <NavLink to="https://hotels.trendytraveltrips.com/" activeClassName="active">
                    <span className="inline-flex gap-1 hover:border-b-2 hover:border-b-orange-600"><MdHotel className='h-6 w-6 text-[#aa7734]'/>Hotels</span>                    
                  </NavLink>
                </li>
                <li className="py-4">
                  <NavLink to="/Cruise" activeClassName="active">
                    <span className="inline-flex gap-1 hover:border-b-2 hover:border-b-orange-600"><MdDirectionsBoat className='h-6 w-6 text-[#aa7734]'/>Cruise</span>                    
                  </NavLink>
                </li>
                <li className="py-4">
                  <NavLink to="/Cars" activeClassName="active">
                    <span className="inline-flex gap-1 hover:border-b-2 hover:border-b-orange-600"><FaCar className='h-6 w-6 text-[#aa7734]'/>Cars</span>                    
                  </NavLink>
                </li>
                <li className="group relative cursor-pointer mt-[-6px]">
                  <a
                    href="/#home"
                    className="flex h-[72px] items-center gap-[2px] hover:border-b-2 hover:border-b-orange-600"
                  >
                    Quick Links{" "}
                    <span>
                      <FaCaretDown className="transition-all duration-200 group-hover:rotate-180" />
                    </span>
                  </a>
                  <div className="absolute -left-9 z-[9999] hidden w-[150px] rounded-md bg-white p-2 text-black group-hover:block shadow-md ">
                    <ul className="space-y-3">
                      {QuickLinks.map((data) => (
                        <li key={data.name}>
                          <a
                            className="inline-block w-full rounded-md p-2 hover:bg-primary/20"
                            href={data.link}
                          >
                            {data.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div className="flex items-center gap-4">
                {user ? (
                  // Logout Icon if user is logged in
                  <FaSignOutAlt 
                    className="text-2xl cursor-pointer text-[#D18700] hover:text-orange-400"
                    onClick={handleLogout}
                    title="Logout"
                  />
                ) : (
                  <>
                    {/* Login Icon */}
                    <FaSignInAlt
                      className="text-2xl cursor-pointer text-[#D18700] hover:text-orange-400"
                      onClick={() => navigate("/login")}
                      title="Login"
                    />
                    {/* Register Icon */}
                    <FaUserPlus
                      className="text-2xl cursor-pointer text-[#D18700] hover:text-orange-400"
                      onClick={() => navigate("/register")}
                      title="Register"
                    />
                  </>
                )}
              <div className="md:hidden block">
                {showMenu ? (
                  <HiMenuAlt1
                    onClick={toggleMenu}
                    className=" cursor-pointer transition-all"
                    size={30}
                  />
                ) : (
                  <HiMenuAlt3
                    onClick={toggleMenu}
                    className="cursor-pointer transition-all"
                    size={30}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <ResponsiveMenu setShowMenu={setShowMenu} showMenu={showMenu} />
      </nav>
    </>
  );
};

export default Navbar;
