import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const Login = () => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const { loading, error, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, credentials);

      if (res.data.isAdmin) {
        localStorage.setItem("access_token", res.data.token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
        dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
        navigate("/admin");
      } else {
        dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
        navigate("/");
      }
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE", payload: err.response?.data || { message: "Login failed" } });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-[80vh] bg-gray-100">
      <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-gray-700 mb-6">Login</h2>
        <div className="space-y-4">
          <input
            type="text"
            placeholder="Username"
            id="username"
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:border-orange-500"
            required
          />
          <input
            type="password"
            placeholder="Password"
            id="password"
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:border-orange-500"
            required
          />
          <button
            disabled={loading}
            onClick={handleClick}
            className={`w-full px-4 py-2 font-semibold text-white rounded-lg focus:outline-none ${
              loading ? "bg-green-500 cursor-not-allowed" : "bg-orange-400 hover:bg-orange-600"
            }`}
          >
            {loading ? "Logging in..." : "Login"}
          </button>
          {error && <span className="block mt-2 text-center text-red-500 text-sm">{error.message}</span>}
        </div>
      </div>
    </div>
  );
};

export default Login;
