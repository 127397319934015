import React from 'react'
import InsuranceList from '../components/Insurance/Insurance'

const Insurance = () => {
  return (
    <div>
      <InsuranceList/>
    </div>
  )
}

export default Insurance