import React from "react";
import CreatePackageForm from "../../components/admin/CreatePackageForm";

const CreatePackage = () => {
  return (
    <div className="admin-dashboard">
      <CreatePackageForm/>
    </div>
  );
};

export default CreatePackage;