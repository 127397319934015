import React, { useState } from 'react';
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Insurance = () => {

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    destination: '',
    departure: '',
    return: ''
  });

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
          ...prevData,
          [name]: value
      }));
  };

  const onSubmit = async (event) => {
      event.preventDefault();

      try {
          const formData = new FormData(event.target);    
          formData.append("access_key", "594d7247-b57d-4675-be89-337660ecb978");
      
          const object = Object.fromEntries(formData);
          const json = JSON.stringify(object);
      
          const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              Accept: "application/json"
          },
          body: json
          }).then((res) => res.json());
      
          if (res.success) {
              toast.success('Your message has been sent successfully', { hideProgressBar: true, autoClose: 2000 });
              setFormData({
                fullName: '',
                email: '',
                phoneNumber: '',
                destination: '',
                departure: '',
                return: ''
              });
          }else{
              const errorData = await res.json();
                  toast.error(errorData.message + " Your message failed, please try again", { hideProgressBar: true, autoClose: 2000 });
          }
      } catch (error) {
          toast.error(error.message + " Your message failed, please try again", { hideProgressBar: true, autoClose: 2000 });
      }
  };

  return (
    <>
      <div className="relative h-72 md:h-80 flex content-center items-center rounded-md border shadow-black justify-center pt-6 pb-16 md:pt-16 md:pb-32">
        <div className="absolute top-0 h-full w-full herobgc bg-cover bg-center rounded-md border shadow-black">                        
        </div>
        <div className="absolute top-0 h-full w-full bg-black/0 bg-cover bg-center rounded-md border shadow-black">                            
        </div>
        <div className="max-w-8xl container relative mx-auto">
            <div className="flex flex-wrap items-center">
            </div>
        </div>
      </div>
      <div class="container mx-auto mt-8">
        <ToastContainer />
        <h2 class="text-3xl font-bold mb-4">Travel Insurance</h2>
        <p class="text-gray-700 mb-8">Travel insurance is essential for a worry-free journey. At Your Travel Agency, we offer comprehensive travel insurance plans tailored to your needs, ensuring you have peace of mind wherever you go.</p>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div class="bg-white shadow-lg p-6 rounded-lg">
                <h3 class="text-xl font-semibold mb-4">Why Choose Our Travel Insurance?</h3>
                <ul class="list-disc list-inside">
                    <li>24/7 Emergency Assistance</li>
                    <li>Medical Coverage</li>
                    <li>Travel Assistance Services</li>
                    <li>Lost Luggage Coverage</li>
                    <li>Trip Cancellation/Interruption Insurance</li>
                    <li>Customizable Plans</li>
                </ul>
                <br/>
                <p>
                  Our comprehensive travel insurance packages offer peace of mind with a range of essential benefits. 
                  You’ll have <b>24/7 Emergency Assistance</b>, ensuring help is just a call away, no matter where you are. 
                  <b>Medical Coverage</b> takes care of unexpected health expenses during your trip, while <b>Travel Assistance Services</b> 
                  provide support with everything from lost passports to emergency travel arrangements. For those concerned about their belongings, 
                  <b>Lost Luggage Coverage</b> ensures you’re protected against mishandled or lost bags. We also offer <b>Trip Cancellation/Interruption Insurance</b>, 
                  which safeguards your investment in case unforeseen events prevent or cut short your travel plans. 
                  Best of all, our plans are <b>customizable</b>, allowing you to select the coverage that best fits your unique travel needs.
                </p>
            </div>

            <div class="bg-white shadow-lg p-6 rounded-lg">
                <h3 class="text-xl font-semibold mb-4">Get a Quote</h3>
                <p class="text-gray-700 mb-4">Fill out the form below to get a personalized quote for your travel insurance:</p>
                <form onSubmit={onSubmit} class="grid grid-cols-1 gap-4">
                  <label for="fullName">Full Name</label>
                  <input type="text" value={formData.fullName} onChange={handleChange} required id="fullName" name="fullName" placeholder="Full Name" class="border p-2 rounded-md"/>
                  
                  <label for="email">Your Email</label>
                  <input type="email" value={formData.email} onChange={handleChange} required id="email" name="email" placeholder="Your Email" class="border p-2 rounded-md"/>
                  
                  <label for="phoneNumber">Phone Number</label>
                  <input type="text" value={formData.phoneNumber} onChange={handleChange} required id="phoneNumber" name="phoneNumber" placeholder="Phone Number" class="border p-2 rounded-md"/>
                  
                  <label for="destination">Destination</label>
                  <input type="text" value={formData.destination} onChange={handleChange} required id="destination" name="destination" placeholder="Destination" class="border p-2 rounded-md"/>
                  
                  <label for="departure">Departure Date</label>
                  <input type="date" value={formData.departure} onChange={handleChange} required id="departure" name="departure" class="border p-2 rounded-md"/>
                  
                  <label for="return">Return Date</label>
                  <input type="date" value={formData.return} onChange={handleChange} required id="return" name="return" class="border p-2 rounded-md"/>
                  
                  <button type="submit" class="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600">Get Quote</button>
              </form>
            </div>
          </div>
      </div>
    </>
  )
}

export default Insurance