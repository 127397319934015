import React from 'react'
import TravelWidget from '../Widget/TravelWidget';

const Cars = () => {
  return (
    <>
      <div className="dark:bg-gray-900 dark:text-white bg-gray-50 py-10">
          <section data-aos="fade-up" className="container ">
            <h1 className=" my-8 border-l-8 border-primary/50 py-2 pl-2 text-3xl font-bold">
                Best Car Deals Search
            </h1>
            <TravelWidget/>
          </section>
      </div>             
    </>
  )
}

export default Cars