import React from 'react'

const Destination = () => {
  return (
      <div className="relative h-72 md:h-80 flex content-center items-center rounded-md border shadow-black justify-center pt-6 pb-16 md:pt-16 md:pb-32">
        <div className="absolute top-0 h-full w-full herobgc bg-cover bg-center rounded-md border shadow-black">                        
        </div>
        <div className="absolute top-0 h-full w-full bg-black/0 bg-cover bg-center rounded-md border shadow-black">                            
        </div>
        <div className="max-w-8xl container relative mx-auto">
            <div className="flex flex-wrap items-center">
            </div>
        </div>
      </div>
  )
}

export default Destination