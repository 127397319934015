import React from 'react'
import useFetch from "../../hooks/useFetch";
import PackageCard from '../featured/PackageCard';

const Cruise = () => {
  const { data, loading, error } = useFetch(
    `${process.env.REACT_APP_API_URL}/packages/getPackagesByType`
  ); 

  // Check if data is an array and has items
  if (!Array.isArray(data) || data.length === 0) {
    return <div>No Cruise available</div>;
  }

  return (
    <div>
        {loading ? (
            "Loading please wait"
        ) : (
        <>
            <div className="dark:bg-gray-900 dark:text-white bg-gray-50 py-10">
                <section data-aos="fade-up" className="container ">
                <h1 className=" my-8 border-l-8 border-primary/50 py-2 pl-2 text-3xl font-bold">
                    Best Package Deals
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {data.map((item, index) => (
                    <PackageCard
                        key={index}
                        {...item}
                    />
                    ))}
                </div>
                </section>
            </div>
        </>
      )}
    </div>
  );
};

export default Cruise