import React from "react";
import { FaUserCircle } from "react-icons/fa";
import Logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { NavbarLinks } from "./Navbar";

const ResponsiveMenu = ({ showMenu, setShowMenu }) => {
  return (
    <div
      className={`${
        showMenu ? "left-0" : "-left-[100%]"
      } fixed bottom-0 top-0 z-20 flex h-screen w-[75%] flex-col justify-between bg-gradient-to-r from-purple-500 via-blue-500 to-green-500 dark:bg-gray-900 dark:text-white px-8 pb-6 pt-16 text-white transition-all duration-200 md:hidden rounded-r-xl shadow-md`}
    >
      <div className="card">
        <div className="flex items-center justify-start gap-3">
          <Link to={"/"} onClick={() => { window.scrollTo(0, 0); setShowMenu(false); }}>
            <img src={Logo} alt="Logo" className="sm:my-2 w-32 h-10 ml-1" />
          </Link>
        </div>
        <nav className="mt-12">
          <ul className="space-y-4 text-xl">
            {NavbarLinks.map((data) => (
              <li key={data.name} className="flex items-center gap-3 hover:bg-slate-500 px-2 rounded-md">
                <data.icon className="text-white h-6 w-6" />
                <Link
                  to={data.link}
                  onClick={() => setShowMenu(false)}
                  className="mb-2 pt-3 inline-block text-white hover:text-gray-300"
                >
                  {data.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="footer">
        <h1 className="text-white">
          @Copyright 2024 || Made with ❤️ by <a href="https://flawlessdigital.tech" className="underline">Trendy Dialog</a>
        </h1>
      </div>
    </div>
  );
};

export default ResponsiveMenu;
