import React from 'react'
import CruiseComp from '../components/Cruise/Cruise'

const Cruise = () => {
  return (
    <div>
        <CruiseComp/>
    </div>
  )
}

export default Cruise