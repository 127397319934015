import React from 'react'
import {IoCarSportSharp } from "react-icons/io5";

const Service = () => {
  return (
<div class="container mx-auto mt-8">
        <h2 class="text-3xl font-bold mb-4">Our Travel Services</h2>
        <p class="text-gray-700 mb-8">Discover a world of possibilities with our wide range of travel services. Whether you're looking for an exotic getaway, an adventurous expedition, or a relaxing cruise, we've got you covered.</p>

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div class="bg-white shadow-lg rounded-lg">
                <img src="https://via.placeholder.com/500x300" alt="Vacation Packages" class="w-full h-48 object-cover"/>
                <div class="p-6">
                    <h3 class="text-xl font-semibold mb-2">Vacation Packages</h3>
                    <p class="text-gray-700">Explore our curated vacation packages tailored to suit every traveler's preferences.</p>
                    <a href="#" class="text-blue-500 hover:underline mt-2 inline-block">Learn More</a>
                </div>
            </div>

            <div class="bg-white shadow-lg rounded-lg">
                <img src="https://via.placeholder.com/500x300" alt="Adventure Tours" class="w-full h-48 object-cover"/>
                <div class="p-6">
                    <h3 class="text-xl font-semibold mb-2">Adventure Tours</h3>
                    <p class="text-gray-700">Embark on thrilling adventures with our range of adrenaline-pumping tours.</p>
                    <a href="#" class="text-blue-500 hover:underline mt-2 inline-block">Learn More</a>
                </div>
            </div>

            <div class="bg-white shadow-lg rounded-lg">
                <img src="https://via.placeholder.com/500x300" alt="Cruise Vacations" class="w-full h-48 object-cover"/>
                <div class="p-6">
                    <h3 class="text-xl font-semibold mb-2">Cruise Vacations</h3>
                    <p class="text-gray-700">Set sail on a luxurious cruise vacation and explore multiple destinations in one journey.</p>
                    <a href="#" class="text-blue-500 hover:underline mt-2 inline-block">Learn More</a>
                </div>
            </div>

            <div class="bg-white shadow-lg rounded-lg">
                <img src="https://via.placeholder.com/500x300" alt="Luxury Escapes" class="w-full h-48 object-cover"/>
                <div class="p-6">
                    <h3 class="text-xl font-semibold mb-2">Luxury Escapes</h3>
                    <p class="text-gray-700">Experience the epitome of luxury with our exclusive escapes to lavish resorts.</p>
                    <a href="#" class="text-blue-500 hover:underline mt-2 inline-block">Learn More</a>
                </div>
            </div>

            <div class="bg-white shadow-lg rounded-lg">
                <img src="https://via.placeholder.com/500x300" alt="Group Travel" class="w-full h-48 object-cover"/>
                <div class="p-6">
                    <h3 class="text-xl font-semibold mb-2">Group Travel</h3>
                    <p class="text-gray-700">Travel with friends, family, or colleagues and enjoy customized group packages.</p>
                    <a href="#" class="text-blue-500 hover:underline mt-2 inline-block">Learn More</a>
                </div>
            </div>

            <div class="bg-white shadow-lg rounded-lg">
                <img src="https://via.placeholder.com/500x300" alt="Wellness Retreats" class="w-full h-48 object-cover"/>
                <div class="p-6">
                    <h3 class="text-xl font-semibold mb-2">Wellness Retreats</h3>
                    <p class="text-gray-700">Rejuvenate your mind, body, and soul with our wellness retreats offering yoga, spa treatments, and more.</p>
                    <a href="#" class="text-blue-500 hover:underline mt-2 inline-block">Learn More</a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Service