import {IoLogoLinkedin, IoLogoWhatsapp, IoLogoFacebook, IoLogoInstagram, IoMailSharp, IoCallSharp, IoLocateSharp } from "react-icons/io5";
import React, { useState } from 'react';
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Contact() {

    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const onSubmit = async (event) => {
        event.preventDefault();

        try {
            const formData = new FormData(event.target);    
            formData.append("access_key", "594d7247-b57d-4675-be89-337660ecb978");
        
            const object = Object.fromEntries(formData);
            const json = JSON.stringify(object);
        
            const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: json
            }).then((res) => res.json());
        
            if (res.success) {
                toast.success('Your message has been sent successfully', { hideProgressBar: true, autoClose: 2000 });
                setFormData({
                    name: '',
                    surname: '',
                    email: '',
                    message: ''
                });
            }else{
                const errorData = await res.json();
                    toast.error(errorData.message + " Your message failed, please try again", { hideProgressBar: true, autoClose: 2000 });
            }
        } catch (error) {
            toast.error(error.message + " Your message failed, please try again", { hideProgressBar: true, autoClose: 2000 });
        }
    };

    return (
        <div className='grid grid-cols-1 gap-4 md:grid-cols-2 sm:pt-10'>            
            <div className='container'>
                <ToastContainer />
                <h1 className='text-3xl text-start ml-2 w-full flex py-6'>Contact us</h1>
                <div>
                    <div className='inline-flex py-4'>
                        <IoLocateSharp className='w-10 h-6 text-[#EA9937]'/> Address:
                    </div>
                    <div className='flex pl-10 leading-8'>
                        13 The Manhattan Lifestyle Estate <br/>
                        Lenchen Avenue <br/>
                        Centurion <br/>
                        0157
                    </div>
                </div>
                <br/>
                <div className='inline-flex py-4 gap-2'>
                    <IoCallSharp className='w-10 h-6 text-[#EA9937]'/> +27 73 559 2210<br/>
                </div>
                <br/>
                <div className='inline-flex py-4 gap-2'>
                    <IoMailSharp className='w-10 h-6 text-[#EA9937]'/> admin@trendydialog.com 
                </div>
                <br/>
                <div className='inline-flex py-4 gap-2 justify-center px-2'>
                    <IoLogoLinkedin className='w-8 h-8 text-[#EA9937] hover:text-black'/>
                    <IoLogoFacebook className='w-8 h-8 text-[#EA9937] hover:text-black'/>
                    <IoLogoInstagram className='w-8 h-8 text-[#EA9937] hover:text-black'/>
                    <IoLogoWhatsapp className='w-8 h-8 text-[#EA9937] hover:text-black'/>
                </div>
            </div>
            <div className='container'>
                <form onSubmit={onSubmit}>
                    <div className="mb-6">
                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                        <input type="name" name="name" id="name" value={formData.name}
                            onChange={handleChange} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="First Name" required/>
                    </div>
                    <div className="mb-6">
                        <label htmlFor="surname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                        <input type="surname" name="surname" id="surname" value={formData.surname}
                            onChange={handleChange} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Last Name" required/>
                    </div>
                    <div className="mb-6">
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                        <input type="email" name="email" id="email" value={formData.email}
                            onChange={handleChange} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Email" required/>
                    </div>
                    <div className="mb-6">                    
                        <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your message</label>
                        <textarea name="message" id="message" value={formData.message}
                            onChange={handleChange} required rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your thoughts here..."></textarea>
                    </div>
                    <button
                        type="submit" className="text-white bg-teal-500 hover:bg-teal-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-blue-800">
                        Submit
                    </button>
                </form>
            </div>
            <div className='flex w-full items-center justify-center md:col-span-4 border shadow-md rounded-md mt-4'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3589.896222554225!2d28.134013075404003!3d-25.872892777282246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95617664507743%3A0x5d4a89161a6a3e5d!2sMall%40Reds%20Shopping%20Centre!5e0!3m2!1sen!2sza!4v1709415002087!5m2!1sen!2sza" className='flex w-full shadow-md rounded' width="600" height="450" allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    );
}