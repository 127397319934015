import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Booking = () => {
  const form = useRef();
  const location = useLocation();
  const { packageData } = location.state;

  const initialFormData = {
    fullName: '',
    email: '',
    phone: '',
    guests: 1,
    message: '',
    departure: '',
    return: '',
    country: packageData.country,
    city: packageData.city,
    type: packageData.type,
    price: packageData.price,
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      destination: packageData.destination,
      type: packageData.type,
      price: packageData.price,
    }));
  }, [packageData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_0z5qkjj', 'template_b10vuyu', form.current, {
        publicKey: '5XLv_WzZJvbkUuzu8',
      })
      .then((result) => {
        toast.success('Email sent successfully!');
        setFormData(initialFormData);
      }, (error) => {
        console.log(error.text);
        toast.error('Failed to send email. Please try again.');
      });

    e.target.reset();
  };

  return (
    
    <div className="max-w-4xl mx-auto my-2 p-6 bg-white shadow-md rounded-lg">
      <ToastContainer />
      <h2 className="text-2xl font-bold mb-4">Booking Form</h2>
      <p className="mb-4">{packageData.desc}</p>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-sm font-bold text-gray-700">Package</label>
          <p className="mt-1 text-gray-900">{packageData.title}</p>
        </div>
        <div>
          <label className="block text-sm font-bold text-gray-700">Type</label>
          <p className="mt-1 text-gray-900">{packageData.type}</p>
        </div>
        <div className="col-span-2">
          <label className="block text-sm font-bold text-gray-700">Photos</label>
          <div className="mt-1 grid grid-cols-3 gap-2">
            {packageData.photos.slice(0, 3).map((photo, index) => (
              <img key={index} src={photo} alt={`Photo ${index + 1}`} className="w-full h-32 object-cover rounded" />
            ))}   
          </div>
        </div>
      </div>
      <form ref={form} onSubmit={sendEmail} className="grid grid-cols-2 gap-4">
        <input type="hidden" name="country" value={formData.country} />
        <input type="hidden" name="city" value={formData.city} />
        <input type="hidden" name="type" value={formData.type} />
        <input type="hidden" name="price" value={formData.price} />
        <div className="mb-4">
          <label className="block text-sm font-bold text-gray-700" htmlFor="name">Full Name</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.name}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold text-gray-700" htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold text-gray-700" htmlFor="phone">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold text-gray-700" htmlFor="guests">Number of Guests</label>
          <input
            type="number"
            id="guests"
            name="guests"
            value={formData.guests}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded"
            min="1"
            max={packageData.maxGuests}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold text-gray-700" htmlFor="departure">Departure Date</label>
          <input
            type="date"
            id="departure"
            name="departure"
            value={formData.departure}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold text-gray-700" htmlFor="return">Return Date</label>
          <input
            type="date"
            id="return"
            name="return"
            value={formData.return}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded"
            required
          />          
        </div>
        <div className="col-span-2 mb-4">
          <label className="block text-sm font-bold text-gray-700" htmlFor="specialRequests">Special Requests</label>
          <textarea
            id="message"
            name="message"
            value={formData.specialRequests}
            className="mt-1 p-2 w-full border rounded"
            rows="4"
          />
        </div>
        <div className="col-span-2">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white font-bold rounded hover:bg-blue-700"
          >
            Submit Booking
          </button>
        </div>
      </form>
    </div>
  );
};

export default Booking;