import React from "react";
import PackageList from "../../components/admin/PackageList";

const AdminDashboard = () => {
  return (
    <div className="admin-dashboard">
      <PackageList/>
    </div>
  );
};

export default AdminDashboard;
