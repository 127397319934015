import { createContext, useEffect, useReducer } from "react";

// Initial state for the AuthContext
const INITIAL_STATE = {
  user: JSON.parse(localStorage.getItem("user")) || null, // User details
  isAdmin: JSON.parse(localStorage.getItem("isAdmin")) || false, // Admin status
  token: localStorage.getItem("access_token") || null, // JWT token
  loading: false, // Loading state for async actions
  error: null, // Error messages
};

// Create the AuthContext with the initial state
export const AuthContext = createContext(INITIAL_STATE);

// Reducer function to handle state transitions based on dispatched actions
const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "LOGIN_SUCCESS":
      return {
        user: action.payload.details,
        isAdmin: action.payload.isAdmin,
        token: action.payload.token,
        loading: false,
        error: null,
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "LOGOUT":
      return {
        user: null,
        isAdmin: false,
        token: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

// AuthContextProvider component to wrap around the parts of the app that need access to AuthContext
export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  // Side effect to update localStorage whenever user, isAdmin, or token changes
  useEffect(() => {
    if (state.user) {
      localStorage.setItem("user", JSON.stringify(state.user));
      localStorage.setItem("isAdmin", JSON.stringify(state.isAdmin));
      localStorage.setItem("access_token", state.token);
    } else {
      localStorage.removeItem("user");
      localStorage.removeItem("isAdmin");
      localStorage.removeItem("access_token");
    }
  }, [state.user, state.isAdmin, state.token]);

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        isAdmin: state.isAdmin,
        token: state.token,
        loading: state.loading,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
