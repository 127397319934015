import React from "react";
import Featured from "../../components/featured/Featured";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Hero from "../../components/Hero/Hero";
import Search from "../../components/Hero/Search";
import NatureVid from "../../assets/video/main.mp4";
import Places from "../../components/Places/Places";
import Packages from "../../components/featured/Packages";
import BannerPic from "../../components/BannerPic/BannerPic";
import BannerImg from "../../assets/cover-women.jpg";
import Banner2 from "../../assets/travel-cover2.jpg";
import FeaturedHotels from "../../components/FeaturedHotels/FeaturedHotels";
import Banner from "../../components/Banner/Banner";
import Testimonial from "../../components/Testimonial/Testimonial";
import MailList from "../../components/mailList/MailList";
import Navbar from "../../components/navbar/Navbar";
import PropertyList from "../../components/propertyList/PropertyList";
import OrderPopup from "../../components/OrderPopup/OrderPopup";

const Home = () => {

  const [orderPopup, setOrderPopup] = React.useState(false);

  const handleOrderPopup = () => {
    setOrderPopup(!orderPopup);
  };

  return (
    <>
      <div>
        {/* <Navbar/> */}
        <div className="h-[700px] relative">
          <video
            autoPlay
            loop
            muted
            className="absolute right-0 top-0 h-[700px] w-full object-cover z-[-1]"
          >
            <source src={NatureVid} type="video/mp4" />
          </video>
          <Hero />
        </div> 
        <Packages/>
        <BannerPic img={Banner2} /> 
        <Places handleOrderPopup={handleOrderPopup} /> 
        <BannerPic img={BannerImg} /> 
        {/* <FeaturedHotels/>    */}          
        {/* <Header/>  */}
        {/* <Featured/> */}
        {/* <h1 className="homeTitle">Browse by property type</h1> */}
        {/* <PropertyList/>      */}
        {/* <MailList/> */}
        {/* <Footer/> */}
        <Banner />
        {/* <Testimonial/> */}
        <OrderPopup orderPopup={orderPopup} setOrderPopup={setOrderPopup} />
      </div>
    </>
  );
};

export default Home;
