import React from 'react'
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { format } from "date-fns";
import PackageCard from "../components/featured/PackageCard";
import { DateRange } from "react-date-range";
import useFetch from "../hooks/useFetch";

const SearchResultsList = () => {
    const location = useLocation();
    const [destination, setDestination] = useState(location.state.destination);
    const [date, setDate] = useState(location.state.date);
    const [maxPrice, setMaxPrice] = useState(location.state.maxPrice);

    const { data, loading, error, reFetch} = useFetch(
        `${process.env.REACT_APP_API_URL}/packages/getSearchedPackages?destination=${destination}&date=${date}&maxPrice=${maxPrice}`
    );

    // Check if data is an array and has items
    if (!Array.isArray(data) || data.length === 0) {
        return <div>No Results available</div>;
    }

    const handleClick = () => {
        reFetch();
    };

    return (
        <div>
            {loading ? (
                    "Loading please wait"
            ) : (
            <>
                <div className="dark:bg-gray-900 dark:text-white bg-gray-50 py-10">
                    <section data-aos="fade-up" className="container ">
                        <h1 className=" my-8 border-l-8 border-primary/50 py-2 pl-2 text-3xl font-bold">
                                Package Search Results
                        </h1>
                        {data.length === 0 ? (
                                <p>No results found.</p>
                        ) : (
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                        {data.map((item, index) => (
                                        <PackageCard
                                                key={index}
                                                {...item}
                                        />
                                        ))}
                                </div>
                        )}
                    </section>
                </div>
            </>
            )}
        </div>
    );
};

export default SearchResultsList