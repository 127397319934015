import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TripPlan = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_0z5qkjj', 'template_fkpecd3', form.current, {
        publicKey: '5XLv_WzZJvbkUuzu8',
      })
      .then((result) => {
        toast.success('Email sent successfully!');
      }, (error) => {
        toast.error('Failed to send email. Please try again.');
      });

    e.target.reset();
  };

  return (
    <>
      <div className="relative h-72 md:h-80 flex content-center items-center rounded-md border shadow-black justify-center pt-6 pb-16 md:pt-16 md:pb-32">
        <div className="absolute top-0 h-full w-full herobgc bg-cover bg-center rounded-md border shadow-black">                        
        </div>
        <div className="absolute top-0 h-full w-full bg-black/0 bg-cover bg-center rounded-md border shadow-black">                            
        </div>
        <div className="max-w-8xl container relative mx-auto">
            <div className="flex flex-wrap items-center">
            </div>
        </div>
      </div>

      <div class="container mx-auto mt-8">
        <h2 class="text-3xl font-bold mb-4">Plan Your Trip</h2>
        <p class="text-gray-700 mb-8">Tell us about your dream vacation, and we'll help you plan the perfect trip tailored to your preferences and budget.</p>
        <ToastContainer />
        <form ref={form} onSubmit={sendEmail} class="w-full mx-auto bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
            
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div class="mb-4">
                    <label for="destination" class="block text-gray-700 text-sm font-bold mb-2">Destination:</label>
                    <input type="text" id="destination" name="destination" placeholder="Enter your destination" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
                </div>  
                <div class="mb-4">
                    <label for="guests" class="block text-gray-700 text-sm font-bold mb-2">Number of Guests:</label>
                    <input type="number" id="guests" name="guests" placeholder="Enter number of guests" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
                </div>
                <div>
                    <label for="departure" class="block text-gray-700 text-sm font-bold mb-2">Departure Date:</label>
                    <input type="date" id="departure" name="departure" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
                </div>
                <div>
                    <label for="return" class="block text-gray-700 text-sm font-bold mb-2">Return Date:</label>
                    <input type="date" id="return" name="return" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
                </div>
                <div class="mb-4">
                  <label for="holidayType" class="block text-gray-700 text-sm font-bold mb-2">Type of Holiday:</label>
                  <select id="holidayType" name="holidayType" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                      <option value="">Please Select...</option>
                      <option value="adventure">Adventure</option>
                      <option value="beach">Beach</option>
                      <option value="city">City</option>
                      <option value="cultural">Cultural</option>
                      <option value="family">Family</option>
                      <option value="romantic">Romantic</option>
                      <option value="wellness">Wellness</option>
                  </select>                  
                </div>
                <div class="mb-4">
                  <label for="budget" class="block text-gray-700 text-sm font-bold mb-2">Budget (optional):</label>
                  <input type="number" id="budget" name="budget" placeholder="Enter your budget" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
                </div>                 
                <div>
                    <label for="name" class="block text-gray-700 text-sm font-bold mb-2">First Name:</label>
                    <input type="text" id="firstName" name="firstName" placeholder="Enter your name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
                </div>
                <div>
                    <label for="name" class="block text-gray-700 text-sm font-bold mb-2">Last Name:</label>
                    <input type="text" id="lastName" name="lastName" placeholder="Enter your name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
                </div>
                <div>
                    <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email:</label>
                    <input type="email" id="email" name="email" placeholder="Enter your email" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
                </div>
                <div>
                    <label for="phone" class="block text-gray-700 text-sm font-bold mb-2">Phone:</label>
                    <input type="tel" id="phone" name="phone" placeholder="Enter your phone number" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
                </div>                                
            </div>            
           
            <div>
              <label for="message" class="block text-gray-700 text-sm font-bold mb-2">Message (optional):</label>
              <textarea id="message" name="message" placeholder="Enter your message" rows="4" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></textarea>
            </div>
            
            <div class="flex items-center justify-between mt-8">
                <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Submit Plan</button>
            </div>
        </form>
      </div>
    </>
  )
}

export default TripPlan