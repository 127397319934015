import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreatePackageForm = ({ packageId = null, onComplete }) => {
  const [packageData, setPackageData] = useState({
    title: "",
    type: "",
    country: "",
    city: "",
    duration: 0,
    maxGuests: 0,
    fromDate: "",
    toDate: "",
    price: 0,    
    minAge: 0,
    amenities: [],
    includes: [],
    photos: [],
    desc: "",
    terms: "",  
    featured: false,
  });
  const [files, setFiles] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem("access_token");
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  useEffect(() => {
    if (packageId) {
      setIsUpdating(true);
      axios.get(`${process.env.REACT_APP_API_URL}/packages/${packageId}`)
        .then(res => setPackageData(res.data))
        .catch(err => toast.error("Failed to fetch package details."));
    }
  }, [packageId]);

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setPackageData((prev) => ({
      ...prev,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSelectChange = (e, key) => {
    const selectedValues = Array.from(e.target.selectedOptions, option => option.value);
    setPackageData(prev => ({ ...prev, [key]: selectedValues }));
  };

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const originalAuthHeader = axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["Authorization"];

    try {
      const photoUrls = await Promise.all(
        Array.from(files).map(async (file) => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("upload_preset", "upload");
          const uploadRes = await axios.post("https://api.cloudinary.com/v1_1/dn8636eu9/image/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "X-Requested-With": "XMLHttpRequest",
            },
          });
          return uploadRes.data.url;
        })
      );

      // Restore the Authorization header after uploads
      axios.defaults.headers.common["Authorization"] = originalAuthHeader;    

      const payload = { ...packageData, photos: photoUrls.length ? photoUrls : packageData.photos };
      const request = isUpdating
        ? axios.put(`${process.env.REACT_APP_API_URL}/packages/${packageId}`, payload,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        : axios.post(`${process.env.REACT_APP_API_URL}/packages`, payload, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          });
        
      await request;
      toast.success(`Package ${isUpdating ? "updated" : "created"} successfully!`);
      onComplete && onComplete(); // call if onComplete exists
      navigate('/admin');
    } catch (err) {
      toast.error(`Failed to ${isUpdating ? "update" : "create"} package.`);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/packages/${packageId}`);
      toast.success("Package deleted successfully!");
      onComplete();
    } catch (err) {
      toast.error("Failed to delete package.");
    }
  };

  return (
    <div className="w-full max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">{isUpdating ? "Update" : "Create"} Package</h2>
      <form onSubmit={handleSave} className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <input type="file" multiple onChange={handleFileChange} className="mb-4 block col-span-full" />
        
        {Object.entries(packageData).map(([key, value]) => (
          key === "photos" || key === "includes" || key === "amenities" ? (
            <div key={key} className="col-span-full">
              <label className="block text-gray-700 capitalize mb-1">{key}</label>
              <select multiple id={key} value={value} onChange={(e) => handleSelectChange(e, key)} className="block w-full p-2 border border-gray-300 rounded">
                {key === "includes" && ["Pick and Drop Services", "Breakfast", "1 Meal Per Day", "Visit 7 Best Places in the City", "Insurance", "Food & Drinks"].map(opt => (
                  <option key={opt} value={opt}>{opt}</option>
                ))}
                {key === "amenities" && ["Accepts Credit Cards", "Car Parking", "Free Coupons", "Restaurant", "Smoking Allowed", "Wireless Internet"].map(opt => (
                  <option key={opt} value={opt}>{opt}</option>
                ))}
              </select>
            </div>
          ) : key === "desc" || key === "terms" ? (
            <div key={key} className="mb-4 col-span-full">
              <label htmlFor={key} className="block text-gray-700 capitalize mb-1">{key}</label>
              <textarea
                id={key}
                value={value}
                onChange={handleChange}
                rows="4"
                className="w-full p-2 border border-gray-300 rounded"
              ></textarea>
            </div>
          ) : (
            <div key={key} className={`mb-4 ${key === "featured" ? "inline-flex" : ""}`}>
              <label htmlFor={key} className="block text-gray-700 capitalize mb-1">{key}</label>
              <input
                id={key}
                type={key === "featured" ? "checkbox" : key === "price" || key === "duration" || key === "maxGuests" || key === "minAge" ? "number" : "text"}
                value={key === "featured" ? undefined : value}
                checked={key === "featured" ? value : undefined}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          )
        ))}

        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 col-span-full">
          {isUpdating ? "Update Package" : "Create Package"}
        </button>
        {isUpdating && (
          <button type="button" onClick={handleDelete} className="ml-2 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 col-span-full">
            Delete Package
          </button>
        )}
      </form>
    </div>
  );
};

export default CreatePackageForm;
