import React from "react";
import PlaceCard from "./PlaceCard";
import useFetch from "../../hooks/useFetch";

const Places = () => {

  const types = ['Cruise', 'Adventure']; // Example types
  const fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 3);
  //add 3 months to current date
  const toDate = new Date(fromDate);
  toDate.setMonth(toDate.getMonth() + 6);

  const query = new URLSearchParams({
    types: types.join(','),
    fromDate,
    toDate,
  });

  const { data, loading, error } = useFetch(`${process.env.REACT_APP_API_URL}/packages/getFeaturedCruiseDeals?${query.toString()}`);

    // Check if data is an array and has items
    if (!Array.isArray(data) || data.length === 0) {
      return <div>No cruise available</div>;
    }

  return (
    <div>
      {loading ? (
        "Loading please wait"
      ) : (
      <>
        <div className="dark:bg-gray-900 dark:text-white bg-gray-50 py-10">
          <section data-aos="fade-up" className="container ">
            <h1 className=" my-8 border-l-8 border-primary/50 py-2 pl-2 text-3xl font-bold">
              Best Cruise deals
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {data.map((item, index) => (
                <PlaceCard
                  key={index}
                  {...item}
                />
              ))}
            </div>
          </section>
        </div>
      </>
      )}
    </div>
  );
};

export default Places;
