import React from "react";
import {useNavigate}  from 'react-router-dom';
import { IoLocationSharp } from "react-icons/io5";

const PackageCard = ({
  _id,
  title,
  country,
  city,
  desc,
  price,
  type,
  photos,
}) => {

  const navigate = useNavigate();

  const handleCardClick = (id) => {
    navigate(`/Details/${id}`);
  };

  return (
    <>
      <div
        className="shadow-lg transition-all duration-500 hover:shadow-xl dark:bg-slate-950 dark:text-white cursor-pointer" 
        onClick={() => handleCardClick(_id)}
      >
        <div className="overflow-hidden">
          <img
            src={photos[0]}
            alt="No image"
            className="mx-auto h-[220px] w-full object-cover transition duration-700 hover:skew-x-2 hover:scale-110"
          />
        </div>

        <div className="space-y-2 p-3">
          <h1 className="line-clamp-1 font-bold text-xl">{title}</h1>
          <div className="flex items-center gap-2 opacity-70">
            <IoLocationSharp />
            <span className="mr-auto">{country}</span>
            <span>{city}</span>
          </div>
          <p className="line-clamp-2">{desc}</p>
          <div className="flex items-center justify-between border-t-2 py-3 !mt-3">
            <div className="opacity-70">
              <p>{type}</p>
            </div>
            <div>
              <p className="text-2xl font-bold">R{price}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackageCard;
