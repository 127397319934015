import React, { useEffect } from 'react';

const TravelWidget = () => {
  useEffect(() => {
    if (!document.querySelector("script[src='https://c117.travelpayouts.com/content?currency=zar&trs=195895&shmarker=393538&location=south-africa%2Fjohannesburg%2Fo.r-tambo-international-airport&locale=en&powered_by=false&bg_color=018dab&font_color=333333&button_color=FFA600&button_font_color=ffffff&button_text=Search&promo_id=3873']")) {
        const script = document.createElement('script');
        script.src = "https://c117.travelpayouts.com/content?currency=zar&trs=195895&shmarker=393538&location=south-africa%2Fjohannesburg%2Fo.r-tambo-international-airport&locale=en&powered_by=false&bg_color=018dab&font_color=333333&button_color=FFA600&button_font_color=ffffff&button_text=Search&promo_id=3873";
        script.async = true;
        script.charset = "utf-8";
        document.getElementById('widget-container').appendChild(script);
    }
  }, []);

  return (
    <div id="widget-container" className="widget-container container">
      {/* Widget content will be injected here */}
    </div>
  );
};

export default TravelWidget;
