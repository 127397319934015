import React from 'react'
import useScript from '../../hooks/useScript';

function TourActivities(){
  return(
      useScript('https://www.viator.com/orion/partner/widget.js')
  );
}

const Package = () => {
  return (
    <>
    <div className="relative h-72 md:h-80 flex content-center items-center rounded-md border shadow-black justify-center pt-6 pb-16 md:pt-16 md:pb-32">
      <div className="absolute top-0 h-full w-full herobgc bg-cover bg-center rounded-md border shadow-black">                        
      </div>
      <div className="absolute top-0 h-full w-full bg-black/0 bg-cover bg-center rounded-md border shadow-black">                            
      </div>
      <div className="max-w-8xl container relative mx-auto">
          <div className="flex flex-wrap items-center">
          </div>
      </div>      
    </div>
    <div className='container'>
      <h1 className=" my-8 border-l-8 border-primary/50 py-2 pl-2 text-3xl font-bold">
          Best Value For Money
      </h1>
    </div>
    <div className='w-auto md:ml-24 lg:ml-44'>
      <div data-vi-partner-id="U00477163" data-vi-widget-ref="W-8733a7d3-32ab-4ac6-8d0a-a44667b83503"></div>
      <TourActivities/>
    </div>
    </>
  )
}

export default Package