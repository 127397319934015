import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PackageList = () => {
  const [packages, setPackages] = useState([]);
  const navigate = useNavigate();

  const token = localStorage.getItem("access_token");
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/packages`);
        setPackages(res.data);
      } catch (error) {
        toast.error("Failed to load packages.");
      }
    };

    fetchPackages();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/packages/${id}`);
      setPackages(packages.filter((pkg) => pkg._id !== id));
      toast.success("Package deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete package.");
    }
  };

  const handleEdit = (id) => {
    navigate(`/packages/edit/${id}`);
  };

  const handleView = (id) => {
    navigate(`/packages/view/${id}`);
  };

  const handleCreate = () => {
    navigate("/packages/create");
  };

  return (
    <div className="w-full max-w-6xl mx-auto bg-white p-6 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Packages</h2>
        <button onClick={handleCreate} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
          Create New Package
        </button>
      </div>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Title</th>
            <th className="py-2 px-4 border-b">Type</th>
            <th className="py-2 px-4 border-b">Country</th>
            <th className="py-2 px-4 border-b">Price</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {packages.map((pkg) => (
            <tr key={pkg._id}>
              <td className="py-2 px-4 border-b">{pkg.title}</td>
              <td className="py-2 px-4 border-b">{pkg.type}</td>
              <td className="py-2 px-4 border-b">{pkg.country}</td>
              <td className="py-2 px-4 border-b">{pkg.price}</td>
              <td className="py-2 px-4 border-b flex space-x-2">
                <button
                  onClick={() => handleView(pkg._id)}
                  className="bg-gray-500 text-white px-3 py-1 rounded hover:bg-gray-600"
                >
                  View
                </button>
                <button
                  onClick={() => handleEdit(pkg._id)}
                  className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(pkg._id)}
                  className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PackageList;
