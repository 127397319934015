import React from 'react'
import TripPlanForm from '../components/TripPlan/TripPlan'

const TripPlan = () => {
  return (
    <div>
      <TripPlanForm/>
    </div>
  )
}

export default TripPlan